import { Injectable } from '@angular/core';
import { EquipeInterface } from './equipe-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'app/appconstants';
import { AuthService } from '../auth/auth.service';
import { Equipe } from 'app/_models/equipe/equipe';

@Injectable({
  providedIn: 'root'
})
export class EquipeService extends EquipeInterface {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super();

    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getHeaders() {
    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getEquipeList(regSkip: number, regLimit: number) {
    const customHeaders = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken(),
      skip: regSkip.toString(),
      limit: regLimit.toString()
    });

    return this.http.get<any>(AppConstants.baseURL + 'user', {headers: customHeaders});
  }

  public getEquipe(id: string) {
    this.getHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'user/' + id, {headers: this.headers});
  }

  public postEquipe(equipe: Equipe) {
    return this.http.post<any>(AppConstants.baseURL + 'register', equipe, {headers: this.headers});
  }

  public patchEquipe(equipe: Equipe) {
    return this.http.patch<any>(AppConstants.baseURL + 'user', equipe, {headers: this.headers});
  }

  public search(busca: string) {
    this.getHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'user/search/' + busca, {headers: this.headers});
  }
}
