import { Injectable } from '@angular/core';
import { RegistroInterface } from './registro-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'app/appconstants';
import { AuthService } from '../auth/auth.service';
import { Registro } from 'app/_models/registro/registro';

@Injectable({
  providedIn: 'root'
})
export class RegistroService extends RegistroInterface {
  headersLogged: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super();

    this.headersLogged = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getHeadersLogged() {
    this.headersLogged = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getRegistros(regSkip: number, regLimit: number) {
    const customHeaders = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken(),
      skip: regSkip.toString(),
      limit: regLimit.toString()
    });

    return this.http.get<any>(AppConstants.baseURL + 'registro', {headers: customHeaders});
  }

  public getListRegistros() {
    this.getHeadersLogged();
    return this.http.get<any>(AppConstants.baseURL + 'registro', {headers: this.headersLogged});
  }

  public getRegistro(id: string) {
    this.getHeadersLogged();
    return this.http.get<any>(AppConstants.baseURL + 'registro/' + id, {headers: this.headersLogged});
  }

  public postRegistro(registro: Registro) {
    return this.http.post<any>(AppConstants.baseURL + 'registro', registro, {headers: this.headersLogged});
  }

  public putRegistro(registro: Registro) {
    return this.http.patch<any>(AppConstants.baseURL + 'registro', registro, {headers: this.headersLogged});
  }

  public deleteRegistro(registroId: string) {
    return this.http.delete<any>(AppConstants.baseURL + 'registro/' + registroId, {headers: this.headersLogged});
  }

  public search(valorBusca: number, busca: string) {
    return this.http.get<any>(AppConstants.baseURL + 'registro/search/' + valorBusca + '/' + busca, {headers: this.headersLogged});
  }
}
