import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ClienteService } from 'app/_services/cliente/cliente.service';

@Component({
  selector: 'app-sistema',
  templateUrl: './sistema.component.html',
  styleUrls: ['./sistema.component.scss']
})
export class SistemaComponent implements OnInit {
  email: string;
  password: string;
  private notifier: NotifierService;

  constructor(
    private router: Router,
    notifierService: NotifierService,
    private clienteService: ClienteService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  login() {
    this.clienteService.postLogin(this.email, this.password)
      .toPromise()
      .then((result) => {

        if (parseInt(result['user']['active']) === 0) {
          this.notifier.notify('error', 'Acesso negado!');
        } else {
          localStorage.removeItem('_pb.client');
          localStorage.setItem('_pb.client', JSON.stringify(result));
          this.router.navigateByUrl('/area-cliente');
        }
      }).catch((error) => {
        this.notifier.notify('error', error.error.message);
      });
  }

}
