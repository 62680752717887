import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserInterface } from './user-interface';
import { AppConstants } from 'app/appconstants';
import { AuthService } from '../auth/auth.service';
import { User } from 'app/_models/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends UserInterface {
  headers: HttpHeaders;
  headersLogged: HttpHeaders;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) {
    super();

    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
    });

    this.headersAuth();
  }

  public headersAuth() {
    this.headersLogged = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public postLogin(email: string, password: string) {
    return this.http.post<User>(AppConstants.baseURL + 'login', {email, password}, {headers: this.headers});
  }

  public putPerfil(user: User) {
    this.headersAuth();
    return this.http.put<any>(AppConstants.baseURL + 'user', user, {headers: this.headersLogged});
  }

  public getLogout() {
    this.headersAuth();
    return this.http.get<any>(AppConstants.baseURL + 'logout', {headers: this.headersLogged});
  }

  public tokenExpired() {
    localStorage.removeItem('_pb.user');
    this.router.navigateByUrl('/login');
  }
}
