import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/_services/user/user.service';
import { ClienteService } from 'app/_services/cliente/cliente.service';
declare const $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'meus-registros', title: 'Meus Registros',  icon: 'library_books', class: '' },
];

@Component({
  selector: 'app-cliente-sidebar',
  templateUrl: './cliente-sidebar.component.html',
  styleUrls: ['./cliente-sidebar.component.scss']
})
export class ClienteSidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private router: Router,
    private clienteService: ClienteService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  logout() {
    this.clienteService.getLogout()
      .toPromise()
      .then(() => {
        localStorage.removeItem('_pb.client');
        this.router.navigateByUrl('/sistema');
      }).catch((error) => {
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
        } else {
          if (error.status === 401) {
            this.clienteService.tokenExpired();
          }
        }
      });
  }
}
