import { Component, OnInit } from '@angular/core';
import { Cliente } from 'app/_models/cliente/cliente';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AuthClienteService } from 'app/_services/auth-cliente/auth-cliente.service';
import { ClienteService } from 'app/_services/cliente/cliente.service';
declare var $: any;

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {
  perfilForm: FormGroup;
  submitted: boolean;
  perfil: Cliente;
  senhaDiferente: boolean;
  erroRegex: boolean;
  showPass: boolean;
  showConfirmPass: boolean;
  notifier: NotifierService;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private authClienteService: AuthClienteService,
    private clienteService: ClienteService,
  ) {
    this.notifier = notifierService;
    this.submitted = false;
    this.senhaDiferente = false;
    this.erroRegex = false;

    $(document).ready(function() {
      setTimeout(() => {
        $('#password').val('');
        $('#password_confirmation').val('');
      }, 1000);
    });
  }

  ngOnInit(): void {
    this.perfil = this.authClienteService.getCurrentClientePerfil();

    this.perfilFormBuilder();
  }

  perfilFormBuilder() {
    this.perfilForm = this.formBuilder.group({
      id: ['', []],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', []],
      password_confirmation: ['', []]
    });

    const perfil = {
      id: this.perfil.id ? this.perfil.id : '',
      firstName: this.perfil.firstName ? this.perfil.firstName : '',
      lastName: this.perfil.lastName ? this.perfil.lastName : '',
      email: this.perfil.email ? this.perfil.email : '',
      role: this.perfil.role ? this.perfil.role : '',
      active: this.perfil.active ? this.perfil.active : '',
      password: '',
      password_confirmation: ''
    };

    this.perfilForm.setValue(perfil);
  }

  get p() { return this.perfilForm.controls; }

  updatePerfil() {
    this.submitted = true;

    let password = this.perfilForm.get('password').value;
    let password_confirmation = this.perfilForm.get('password_confirmation').value;

    if (password && password_confirmation) {
      if (password !== password_confirmation) {
        this.senhaDiferente = true;
        return;
      }

      const customRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%&*?]+){6,12}$/;
      password = customRegex.exec(this.perfilForm.get('password').value);
      password_confirmation = customRegex.exec(this.perfilForm.get('password_confirmation').value);

      if (password === null || password_confirmation === null) {
        this.erroRegex = true;
        return;
      }
    }

    if (this.perfilForm.invalid) {
      return;
    }

    let perfil;
    if (password && password_confirmation) {
      perfil = {
        id: this.perfil.id,
        firstName: this.perfilForm.get('firstName').value,
        lastName: this.perfilForm.get('lastName').value,
        email: this.perfilForm.get('email').value,
        role: this.perfil.role ? this.perfil.role : '',
        active: this.perfil.active,
        password: this.perfilForm.get('password').value,
        password_confirmation: this.perfilForm.get('password_confirmation').value
      };

      this.clienteService.patchPerfilCliente(perfil)
        .toPromise()
        .then((result) => {
          this.notifier.notify('success', 'Perfil atualizado com sucesso!');
        }).catch((error) => {
          this.notifier.notify('error', error.error.message);
        });

    } else {
      this.clienteService.getClienteWithPass(this.perfil.id.toString())
        .toPromise()
        .then((cliente) => {
          perfil = {
            id: this.perfil.id,
            firstName: this.perfilForm.get('firstName').value,
            lastName: this.perfilForm.get('lastName').value,
            email: this.perfilForm.get('email').value,
            role: cliente.role,
            password: null,
            password_confirmation: null
          };
    
          this.clienteService.patchPerfilCliente(perfil)
            .toPromise()
            .then((result) => {
              this.notifier.notify('success', 'Perfil atualizado com sucesso!');
            }).catch((error) => {
              this.notifier.notify('error', error.error.message);
            });
        }).catch((error) => {
          this.notifier.notify('error', error.error.message);
        });
    }
  }

  showPassowrd() {
    this.showPass = !this.showPass;
  }

  showConfirmPassowrd() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  limparSenhaError() {
    this.senhaDiferente = false;
    this.erroRegex = false;
  }
}
