import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute } from '@angular/router';
import { ClienteRegistroService } from 'app/_services/clienteRegistro/cliente-registro.service';
import { ClienteService } from 'app/_services/cliente/cliente.service';
import { Registro } from 'app/_models/registro/registro';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-meus-registros',
  templateUrl: './meus-registros.component.html',
  styleUrls: ['./meus-registros.component.scss']
})
export class MeusRegistrosComponent implements OnInit {
  id: string;
  registros: any[] = [];
  registro: Registro;
  showList: boolean;
  showVisualizar: boolean;
  public Editor = ClassicEditor;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clienteRegistroService: ClienteRegistroService,
    private clienteService: ClienteService,
    private spinner: NgxSpinnerService
  ) {
    this.Editor.isReadOnly = true;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.activatedRoute.data.subscribe(data => {
      switch (data.acao) {
        case 'visualizar':
          this.spinner.show();
          this.showList = false;
          this.showVisualizar = true;

          this.clienteRegistroService.getClienteRegistro(parseInt(this.id, 10))
            .toPromise()
            .then((registro) => {
              registro.dataNascimento = this.formatDateToBR(registro.dataNascimento);
              this.registro = registro;
              this.spinner.hide();
            }).catch((error) => {
              this.spinner.hide();
              if (error.error.message === 'Token has expired') {
                this.clienteService.tokenExpired();
              }
            });
          break;
        default:
          this.listagem();
          break;
      }
    });
  }

  listagem() {
    this.showList = true;
    this.showVisualizar = false;
    this.registros = [];
    this.clienteRegistroService.getClienteRegistros()
      .toPromise()
      .then((registros) => {
        if (registros.data.length) {
          this.registros = registros.data;
        }
      }).catch((error) => {});
  }

  formatDateToBR(dataNascimento) {
    const date = dataNascimento.split('-');
    return date[2] + '/' + date[1] + '/' + date[0];
  }

}
