import { User } from 'app/_models/user/user';

export abstract class UserInterface {
  constructor() { }

  abstract postLogin(email: string, password: string);

  abstract putPerfil(user: User);

  abstract getLogout();
}
