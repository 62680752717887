import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { UserService } from 'app/_services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  private notifier: NotifierService;

  constructor(
    private router: Router,
    notifierService: NotifierService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.hide();
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  login() {
    this.spinner.show();

    this.userService.postLogin(this.email, this.password)
      .toPromise()
      .then((result) => {
        this.spinner.hide();
        localStorage.removeItem('_pb.user');
        localStorage.setItem('_pb.user', JSON.stringify(result));
        this.router.navigateByUrl('');
      }).catch((error) => {
        this.spinner.hide();
        this.notifier.notify('error', error.error.message);
      });
  }

}
