import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/_services/user/user.service';
declare const $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'dashboard', title: 'Painel de Controle',  icon: 'dashboard', class: '' },
  { path: 'clientes', title: 'Clientes',  icon: 'people', class: '' },
  { path: 'equipe', title: 'Equipe',  icon: 'gavel', class: '' },
  { path: 'registros', title: 'Registros',  icon: 'library_books', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  logout() {
    this.userService.getLogout()
      .toPromise()
      .then(() => {
        localStorage.removeItem('_pb.user');
        this.router.navigateByUrl('/login');
      }).catch((error) => {
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
        } else {
          if (error.status === 401) {
            this.userService.tokenExpired();
          }
        }
      });
  }
}
