import { Injectable } from '@angular/core';
import { AuthInterface } from './auth-interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AuthInterface {

  constructor() {
    super();
  }

  public getToken() {
    const current = this.getCurrentUsuario();
    return current ? current.token : '';
  }

  public isLoggedIn() {
    return this.getToken();
  }

  public getCurrentUsuario() {
    return JSON.parse(localStorage.getItem('_pb.user'));
  }

  public getCurrentPerfil() {
    const user = JSON.parse(localStorage.getItem('_pb.user'));
    return user ? user.user : '';
  }
}
