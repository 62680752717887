import { Injectable } from '@angular/core';
import { RoleInterface } from './role-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { AppConstants } from 'app/appconstants';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends RoleInterface {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super();

    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getHeaders() {
    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getRoles() {
    this.getHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'role', {headers: this.headers});
  }

  public getRole(roleId: string) {
    this.getHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'role/' + roleId, {headers: this.headers});
  }
}
