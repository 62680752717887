import { Injectable } from '@angular/core';
import { ClienteRegistroInterface } from './cliente-registro-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'app/appconstants';
import { AuthClienteService } from '../auth-cliente/auth-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteRegistroService extends ClienteRegistroInterface {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authClienteService: AuthClienteService
  ) {
    super();
  }

  public getHeader() {
    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authClienteService.getToken()
    });
  }

  public getClienteRegistros() {
    const cliente = this.authClienteService.getCurrentClientePerfil();
    const customHeaders = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authClienteService.getToken(),
      clienteId: cliente.id,
    });

    return this.http.get<any>(AppConstants.baseURL + 'meus-registros', {headers: customHeaders});
  }

  public getClienteRegistro(id: number) {
    this.getHeader();

    return this.http.get<any>(AppConstants.baseURL + 'meu-registro/' + id, {headers: this.headers});
  }
}
