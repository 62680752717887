import { Injectable } from '@angular/core';
import { AuthClienteGuardInterface } from './auth.cliente.guard-interface';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthClienteService } from '../auth-cliente/auth-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class AuthClienteGuard extends AuthClienteGuardInterface {

  constructor(
    private router: Router,
    private authClienteService: AuthClienteService
  ) {
    super();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isUserLoggedIn = this.authClienteService.isClientLoggedIn();

    if (isUserLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/sistema']);
      return false;
    }

    return true;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
