import { Equipe } from 'app/_models/equipe/equipe';

export abstract class EquipeInterface {
  constructor() { }

  abstract getHeaders();

  abstract getEquipeList(regSkip: number, regLimit: number);

  abstract getEquipe(id: string);

  abstract patchEquipe(equipe: Equipe);

  abstract search(busca: string);
}
