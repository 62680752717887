import { Component, OnInit } from '@angular/core';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AppConstants } from 'app/appconstants';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { RegistroService } from 'app/_services/registro/registro.service';
import { ConsultaCepService } from 'app/_services/consulta-cep/consulta-cep.service';
import { EnderecoCEP } from 'app/_models/user/user';
import { Registro } from 'app/_models/registro/registro';
import { UserService } from 'app/_services/user/user.service';
import { AuthService } from 'app/_services/auth/auth.service';
import { Equipe } from 'app/_models/equipe/equipe';
import { RoleService } from 'app/_services/role/role.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.scss']
})
export class RegistrosComponent implements OnInit {
  registroForm: FormGroup;
  submitted: boolean;
  id: string;
  registros: any[] = [];
  tipoBusca: any[] = [];
  valorBusca: number;
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  busca: string;
  showList: boolean;
  showAddEdit: boolean;
  title: string;
  btnAction: string;
  public Editor = ClassicEditor;
  public config = {
    language: 'pt-br'
  };
  notifier: NotifierService;
  perfil: Equipe;
  isAdmin: boolean;
  inputBusca: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private userService: UserService,
    private registroService: RegistroService,
    private consultaCepService: ConsultaCepService,
    private authService: AuthService,
    private roleService: RoleService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {

    this.notifier = notifierService;
    this.showList = false;
    this.showAddEdit = false;
    this.inputBusca = false;
    this.valorBusca = 0;
    this.tipoBusca = [
      { value: 1, viewValue: 'Buscar por nome'},
      { value: 2, viewValue: 'Buscar por número do processo'},
    ];

    this.perfil = this.authService.getCurrentPerfil();

    this.roleService.getRole(this.perfil.role)
      .toPromise()
      .then((role) => {
        this.isAdmin = role.nome === 'administrador' ? true : false;
      }).catch((err) => {});

    this.getRegistros();
  }

  getRegistros() {
    this.registroService.getRegistros(1, 1)
      .toPromise()
      .then((registros) => {
        this.itemsPerPage = AppConstants.pagination.itemsPerPage;
        this.currentPage = AppConstants.pagination.currentPage;
        this.totalItems = registros.total;
      }).catch((error) => {
        if (error.error.message === 'Token has expired') {
          this.userService.tokenExpired();
        }
      });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.activatedRoute.data.subscribe(data => {
      switch (data.acao) {
        case 'novo':
          this.registroFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Novo registro';
          this.btnAction = 'Salvar';
          break;
        case 'editar':
          this.spinner.show();

          this.registroFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Editar registro';
          this.btnAction = 'Atualizar';

          this.registroService.getRegistro(this.id)
            .toPromise()
            .then((registro) => {
              registro.dataNascimento = this.formatDateToBR(registro.dataNascimento);
              this.registroForm.setValue(registro);
              this.spinner.hide();
            }).catch((error) => {
              this.spinner.hide();
              if (error.error.message === 'Token has expired') {
                this.userService.tokenExpired();
              }
            });
          break;
        case 'remover':
          this.spinner.show();

          this.registroService.deleteRegistro(this.id)
            .toPromise()
            .then(() => {
              this.spinner.hide();
              this.router.navigateByUrl('/dashboard/registros');
            }).catch((error) => {
              this.spinner.hide();
              this.notifier.notify('error', 'Erro ao tentar remover registro, entre em contato com o administrador!.');
            });
          break;
        default:
          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
          break;
      }
    });
  }

  listagem(skip: number, limit: number) {
    this.spinner.show();

    this.registroFormBuilder();
    this.showList = true;
    this.showAddEdit = false;
    this.registros = [];
    this.registroService.getRegistros(skip, limit)
      .toPromise()
      .then((registros) => {
        this.registros = registros.data;
        this.spinner.hide();
    }).catch((error) => {});
  }

  registroFormBuilder() {
    this.registroForm = this.formBuilder.group({
      id: ['', []],
      nome: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      email: ['', []],
      telefone: ['', []],
      celular: ['', []],
      profissao: ['', []],
      rg: ['', []],
      cpf: ['', Validators.required],
      cnpj: ['', []],
      cep: ['', []],
      endereco: ['', []],
      numero: ['', []],
      complemento: ['', []],
      bairro: ['', []],
      cidade: ['', []],
      estado: ['', []],
      processo: ['', []],
      vara: ['', []],
      comarca: ['', []],
      autores: ['', []],
      reus: ['', []],
      observacao: ['', []],
      informacaoCliente: ['', []],
      // honorario: ['', []],
      custosDespesas: ['', []],
      valorAcao: ['', []],
      descricao: ['', []],
    });
  }

  get f() { return this.registroForm.controls; }

  salvarRegistro() {
    this.submitted = true;
    this.spinner.show();

    const dataNascimento = this.formatDateToDB(this.registroForm.get('dataNascimento').value);

    if (this.registroForm.invalid) {
      this.spinner.hide();
      setTimeout(() => {
        this.notifier.notify('error', 'Os campos obrigatórios precisam ser preenchidos');
      }, 500);
      return;
    }

    if (this.registroForm.get('id').value) {
      const registro: Registro = {
        id: this.registroForm.get('id').value,
        nome: this.registroForm.get('nome').value,
        dataNascimento: dataNascimento,
        email: this.registroForm.get('email').value,
        telefone: this.registroForm.get('telefone').value,
        celular: this.registroForm.get('celular').value,
        profissao: this.registroForm.get('profissao').value,
        rg: this.registroForm.get('rg').value,
        cpf: this.registroForm.get('cpf').value,
        cnpj: this.registroForm.get('cnpj').value,
        cep: this.registroForm.get('cep').value,
        endereco: this.registroForm.get('endereco').value,
        numero: this.registroForm.get('numero').value,
        complemento: this.registroForm.get('complemento').value,
        bairro: this.registroForm.get('bairro').value,
        cidade: this.registroForm.get('cidade').value,
        estado: this.registroForm.get('estado').value,
        processo: this.registroForm.get('processo').value,
        vara: this.registroForm.get('vara').value,
        comarca: this.registroForm.get('comarca').value,
        autores: this.registroForm.get('autores').value,
        reus: this.registroForm.get('reus').value,
        observacao: this.registroForm.get('observacao').value,
        valorAcao: this.registroForm.get('valorAcao').value,
        informacaoCliente: this.registroForm.get('informacaoCliente').value,
        custosDespesas: this.registroForm.get('custosDespesas').value,
        descricao: this.registroForm.get('descricao').value,
      };

      this.registroService.putRegistro(registro)
        .toPromise()
        .then(() => {
          this.spinner.hide();
          setTimeout(() => {
            this.router.navigateByUrl('/dashboard/registros');
          }, 500);
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    } else {
      this.registroService.postRegistro(this.registroForm.value)
        .toPromise()
        .then(() => {
          this.spinner.hide();
          setTimeout(() => {
            this.router.navigateByUrl('/dashboard/registros');
          }, 500);
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    }
  }

  getCep() {
    if (this.registroForm.get('cep').value) {
      this.consultaCepService.consultaCep(this.registroForm.get('cep').value)
        .toPromise()
        .then((result: EnderecoCEP) => {
          if (result.cep) {
            this.registroForm.controls.endereco.setValue(result.logradouro);
            this.registroForm.controls.bairro.setValue(result.bairro);
            this.registroForm.controls.cidade.setValue(result.localidade);
            this.registroForm.controls.estado.setValue(result.uf);
          }
        }).catch((error) => {});
    }
  }

  valorBuscar() {
    if (this.valorBusca > 0) {
      this.inputBusca = true;
    }
  }

  buscar() {
    this.spinner.show();

    this.registros = [];
    if (this.busca) {
      this.registroService.search(this.valorBusca, this.busca)
      .toPromise()
      .then(resp => {
        this.registros = resp.data;

        this.itemsPerPage = AppConstants.pagination.itemsPerPage;
        this.currentPage = AppConstants.pagination.currentPage;
        this.totalItems = resp.total;

        this.spinner.hide();
      }).catch(error => {});
    } else {
      this.registroService.getRegistros(1, 1)
        .toPromise()
        .then((registros) => {
          this.itemsPerPage = AppConstants.pagination.itemsPerPage;
          this.currentPage = AppConstants.pagination.currentPage;
          this.totalItems = registros.total;

          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    }
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: { title: 'Alerta', content: 'Você tem certeza que deseja apagar o registro?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.router.navigate(['/dashboard/remover-registro', id]);
      }
    });
  }

  limparBusca() {
    this.inputBusca = false;
    this.busca = '';
    this.valorBusca = 0;
    this.getRegistros();
    this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
  }

  pageChanged(event) {
    this.currentPage = event;

    const itemsPerPage = AppConstants.pagination.itemsPerPage;

    const skip = (this.currentPage === 1) ? (this.currentPage - 1) * itemsPerPage : (this.currentPage - 1) * itemsPerPage - 1;

    if (this.inputBusca) {
      this.spinner.show();

      this.registros.slice(skip, this.currentPage * itemsPerPage - 1);

      this.spinner.hide();
    } else {
      this.listagem(skip, this.currentPage * itemsPerPage - 1);
    }
  }

  formatDateToBR(dataNascimento) {
    const date = dataNascimento.split('-');
    return date[2] + '/' + date[1] + '/' + date[0];
  }

  formatDateToDB(dataNascimento) {
    const date = dataNascimento.split('/');
    return date[2] + '-' + date[1] + '-' + date[0];
  }
}
