import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'app/appconstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'app/_services/user/user.service';
import { EquipeService } from 'app/_services/equipe/equipe.service';
import { RoleService } from 'app/_services/role/role.service';
import { Equipe } from 'app/_models/equipe/equipe';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'app/_services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.scss']
})
export class EquipeComponent implements OnInit {
  equipeForm: FormGroup;
  submitted: boolean;
  id: string;
  equipeList: any[] = [];
  roles: any[] = [];
  showList: boolean;
  showAddEdit: boolean;
  senhaDiferente: boolean;
  erroRegex: boolean;
  showPass: boolean;
  showConfirmPass: boolean;
  title: string;
  btnAction: string;
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  busca: string;
  notifier: NotifierService;
  perfil: Equipe;
  isAdmin: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private equipeService: EquipeService,
    private userService: UserService,
    private roleService: RoleService,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.notifier = notifierService;
    this.showList = false;
    this.showAddEdit = false;
    this.senhaDiferente = false;
    this.erroRegex = false;
    this.showPass = false;
    this.showConfirmPass = false;

    this.perfil = this.authService.getCurrentPerfil();

    this.roleService.getRole(this.perfil.role)
      .toPromise()
      .then((role) => {
        this.isAdmin = role.nome === 'administrador' ? true : false;
      }).catch((err) => {});

    this.equipeService.getEquipeList(1, 1)
      .toPromise()
      .then((equipe) => {
        this.itemsPerPage = AppConstants.pagination.itemsPerPage;
        this.currentPage = AppConstants.pagination.currentPage;
        this.totalItems = equipe.total;
      }).catch((error) => {
        if (error.error.message === 'Token has expired') {
          this.userService.tokenExpired();
        }
      });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.activatedRoute.data.subscribe(data => {
      switch (data.acao) {
        case 'novo':
          $(document).ready(function() {
            setTimeout(() => {
              $('#email').val('');
              $('#password').val('');
              $('#password_confirmation').val('');
            }, 1500);
          });

          this.equipeFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Novo suário';
          this.btnAction = 'Salvar';

          this.roleService.getRoles()
            .toPromise()
            .then((roles) => {
              roles.forEach(role => {
                if (role.nome !== 'cliente') {
                  this.roles.push(role);
                }
              });
            }).catch((error) => {
              if (error.error.message === 'Token has expired') {
                this.userService.tokenExpired();
              }
            });
          break;
        case 'editar':
          this.spinner.show();

          $(document).ready(function() {
            setTimeout(() => {
              $('#password').val('');
              $('#password_confirmation').val('');
            }, 1500);
          });
          this.equipeFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Editar usuário';
          this.btnAction = 'Atualizar';

          this.equipeService.getEquipe(this.id)
            .toPromise()
            .then((usuario) => {
              usuario.password = '';
              usuario.password_confirmation = '';
              this.equipeForm.setValue(usuario);

              this.roleService.getRoles()
                .toPromise()
                .then((roles) => {
                  roles.forEach(role => {
                    if (role.nome !== 'cliente') {
                      role.checked = role.id === this.equipeForm.get('role').value ? true : false;
                      this.roles.push(role);
                    }
                  });

                  this.spinner.hide();
                }).catch((error) => {
                  this.spinner.hide();
                  if (error.error.message === 'Token has expired') {
                    this.userService.tokenExpired();
                  }
                });
            }).catch((error) => {
              this.spinner.hide();
              if (error.error.message === 'Token has expired') {
                this.userService.tokenExpired();
              }
            });
          break;
        default:
          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
          break;
      }
    });
  }

  listagem(skip: number, limit: number) {
    this.spinner.show();

    this.equipeFormBuilder();
    this.showList = true;
    this.showAddEdit = false;
    this.equipeList = [];
    this.equipeService.getEquipeList(skip, limit)
      .toPromise()
      .then((equipe) => {
        this.equipeList = equipe.data;

        this.roleService.getRoles()
          .toPromise()
          .then((roles) => {
            roles.forEach(role => {
              if (role.nome !== 'cliente') {
                this.roles.push(role);
              }
            });

            this.equipeList.forEach(item => {
              this.roles.forEach(role => {
                if (item.role === role.id) {
                  item.role = role.nome;
                }
              });
            });

            this.spinner.hide();

          }).catch((error) => {
            this.spinner.hide();
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }
          });
    }).catch((error) => {
      if (error.error.message === 'Token has expired') {
        this.userService.tokenExpired();
      }
    });
  }

  equipeFormBuilder() {
    this.equipeForm = this.formBuilder.group({
      id: ['', []],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      cpf: ['', Validators.required],
      role: ['', []],
      active: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    });
  }

  get f() { return this.equipeForm.controls; }

  buscar() {
    this.equipeList = [];
    if (this.busca) {
      this.equipeService.search(this.busca)
        .toPromise()
        .then(resp => {
            this.equipeList = resp.data;

            this.itemsPerPage = AppConstants.pagination.itemsPerPage;
            this.currentPage = AppConstants.pagination.currentPage;
            this.totalItems = resp.total;
        }).catch(error => {});
    } else {
      this.equipeService.getEquipeList(1, 1)
        .toPromise()
        .then((equipe) => {
          this.itemsPerPage = AppConstants.pagination.itemsPerPage;
          this.currentPage = AppConstants.pagination.currentPage;
          this.totalItems = equipe.total;

          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
        }).catch((error) => {
          this.spinner.hide();

          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    }
  }

  salvarUsuario() {
    this.submitted = true;
    this.spinner.show();

    const active = this.equipeForm.get('active').value ? 1 : 0;
    this.equipeForm.controls.active.setValue(active);

    if (this.equipeForm.invalid) {
      this.spinner.hide();
      setTimeout(() => {
        this.notifier.notify('error', 'Os campos obrigatórios precisam ser preenchidos');
      }, 500);
      return;
    }

    if (this.equipeForm.get('id').value) {
      if (!this.equipeForm.get('password').value || !this.equipeForm.get('password_confirmation').value) {
        const equipe: Equipe = {
          id: this.equipeForm.get('id').value,
          firstName: this.equipeForm.get('firstName').value,
          lastName: this.equipeForm.get('lastName').value,
          email: this.equipeForm.get('email').value,
          cpf: this.equipeForm.get('cpf').value,
          role: this.equipeForm.get('role').value,
          active: this.equipeForm.get('active').value
        };

        this.equipeService.patchEquipe(equipe)
          .toPromise()
          .then(() => {
            this.spinner.hide();
            setTimeout(() => {
              this.notifier.notify('success', 'Usuário atualizado com sucesso!');
            }, 500);
          }).catch((error) => {
            this.spinner.hide();
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }
          });
      } else {

        this.equipeService.patchEquipe(this.equipeForm.value)
          .toPromise()
          .then(() => {
            this.spinner.hide();
            setTimeout(() => {
              this.notifier.notify('success', 'Usuário atualizado com sucesso!');
            }, 500);
          }).catch((error) => {
            this.spinner.hide();
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }
          });
      }
    } else {
      this.equipeService.postEquipe(this.equipeForm.value)
        .toPromise()
        .then(() => {
          this.spinner.hide();
          setTimeout(() => {
            this.notifier.notify('success', 'Usuário salvo com sucesso!');
          }, 500);
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }

          if (error.error.message === 'The password confirmation does not match') {
            this.senhaDiferente = true;
          }
        });
    }
  }

  pageChanged(event) {
    this.currentPage = event;

    const itemsPerPage = AppConstants.pagination.itemsPerPage;
    const skip = (this.currentPage === 1) ? (this.currentPage - 1) * itemsPerPage : (this.currentPage - 1) * itemsPerPage - 1;

    this.listagem(skip, this.currentPage * itemsPerPage - 1);
  }

  showPassowrd() {
    this.showPass = !this.showPass;
  }

  showConfirmPassowrd() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  limparSenhaError() {
    this.senhaDiferente = false;
    this.erroRegex = false;
  }
}
