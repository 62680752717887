import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { EnderecoCEP } from 'app/_models/user/user';
import { ConsultaCepInterface } from './consulta-cep-interface';

@Injectable({
    providedIn: 'root'
})
export class ConsultaCepService extends ConsultaCepInterface {
  constructor(
    private http: HttpClient
  ) {
    super();
  }

  public consultaCep(cep: string) {
    cep = cep.replace(/\D/g, '');

    if (cep !== '') {
      const validacep = /^[0-9]{8}$/;

      if (validacep.test(cep)) {
        return this.http.get<EnderecoCEP>('//viacep.com.br/ws/' + cep + '/json');
      }
    }

    return of({});
  }
}
