import { Injectable } from '@angular/core';
import { ClienteInterface } from './cliente-interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'app/appconstants';
import { Cliente } from 'app/_models/cliente/cliente';
import { Router } from '@angular/router';
import { AuthClienteService } from '../auth-cliente/auth-cliente.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends ClienteInterface {
  headers: HttpHeaders;
  headersClient: HttpHeaders;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authClienteService: AuthClienteService,
    private authService: AuthService
  ) {
    super();

    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });

    this.headersClient = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authClienteService.getToken()
    });
  }

  public getHeaders() {
    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken()
    });
  }

  public getClientHeaders() {
    this.headersClient = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authClienteService.getToken()
    });
  }

  public getClienteHeaders() {
    this.headers = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authClienteService.getToken()
    });
  }

  public postLogin(email: string, password: string) {
    return this.http.post<Cliente>(AppConstants.baseURL + 'sistema', {email, password}, {headers: this.headers});
  }

  public getLogout() {
    this.getClienteHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'sistema-logout', {headers: this.headers});
  }

  public tokenExpired() {
    localStorage.removeItem('_pb.client');
    this.router.navigateByUrl('/sistema');
  }

  public getClientes(regSkip: number, regLimit: number) {
    const customHeaders = new HttpHeaders({
      accept: AppConstants.accept,
      'content-type': AppConstants.contetType,
      Authorization: 'Authorization: Bearer ' + this.authService.getToken(),
      skip: regSkip.toString(),
      limit: regLimit.toString()
    });

    return this.http.get<any>(AppConstants.baseURL + 'cliente', {headers: customHeaders});
  }

  public getCliente(id: string) {
    this.getHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'cliente/' + id, {headers: this.headers});
  }

  public getClienteWithPass(id: string) {
    this.getClientHeaders();
    return this.http.get<any>(AppConstants.baseURL + 'cliente/pass/' + id, {headers: this.headersClient});
  }

  public postCliente(cliente: Cliente) {
    return this.http.post<any>(AppConstants.baseURL + 'cliente', cliente, {headers: this.headers});
  }

  public patchCliente(cliente: Cliente) {
    return this.http.patch<any>(AppConstants.baseURL + 'cliente', cliente, {headers: this.headers});
  }

  public patchPerfilCliente(cliente: Cliente) {
    this.getClientHeaders();
    return this.http.patch<any>(AppConstants.baseURL + 'perfil-cliente', cliente, {headers: this.headersClient});
  }

  public search(busca: string) {
    return this.http.get<any>(AppConstants.baseURL + 'cliente/search/' + busca, {headers: this.headers});
  }
}
