import { Registro } from 'app/_models/registro/registro';

export abstract class RegistroInterface {
    constructor() { }

    abstract getHeadersLogged();

    abstract getRegistros(regSkip: number, regLimit: number);

    abstract getListRegistros();

    abstract getRegistro(id: string);

    abstract postRegistro(registro: Registro);

    abstract putRegistro(registro: Registro);

    abstract search(valorBusca: number, busca: string);
}
