export const AppConstants = Object.freeze({
  // baseURL: 'http://127.0.0.1:8000/api/',

  // baseURL: 'https://api.advocaciapaulobraga.com.br/public/api/',

  baseURL: 'http://api-pb.fcloud.com.br/public/api/',

  accept: 'application/json',
  contetType: 'application/json',

  pagination: {
    itemsPerPage: 20,
    currentPage: 1,
    initSkip: 0
  },
});
