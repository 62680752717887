import { Cliente } from 'app/_models/cliente/cliente';

export abstract class ClienteInterface {
  constructor() { }

  abstract getHeaders();

  abstract postLogin(email: string, password: string);

  abstract getLogout();

  abstract getClientes(regSkip: number, regLimit: number);

  abstract getCliente(id: string);

  abstract postCliente(cliente: Cliente);

  abstract patchCliente(cliente: Cliente);

  abstract search(busca: string);
}
