import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { User } from 'app/_models/user/user';
import { AuthService } from 'app/_services/auth/auth.service';
import { UserService } from 'app/_services/user/user.service';
declare var $: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  perfilForm: FormGroup;
  submitted: boolean;
  perfil: User;
  senhaDiferente: boolean;
  erroRegex: boolean;
  notifier: NotifierService;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.notifier = notifierService;
    this.submitted = false;
    this.senhaDiferente = false;
    this.erroRegex = false;

    $(document).ready(function() {
      setTimeout(() => {
        $('#password').val('');
        $('#password_confirmation').val('');
      }, 1000);
    });
  }

  ngOnInit(): void {
    this.perfil = this.authService.getCurrentPerfil();

    this.perfilFormBuilder();
  }

  perfilFormBuilder() {
    this.perfilForm = this.formBuilder.group({
      id: ['', []],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      cpf: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    });

    const perfil = {
      id: this.perfil.id ? this.perfil.id : '',
      firstName: this.perfil.firstName ? this.perfil.firstName : '',
      lastName: this.perfil.lastName ? this.perfil.lastName : '',
      email: this.perfil.email ? this.perfil.email : '',
      cpf: this.perfil.cpf ? this.perfil.cpf : '',
      password: '',
      password_confirmation: ''
    };

    this.perfilForm.setValue(perfil);
  }

  get p() { return this.perfilForm.controls; }

  updatePerfil() {
    this.submitted = true;

    let password = this.perfilForm.get('password').value;
    let password_confirmation = this.perfilForm.get('password_confirmation').value;

    if (password && password_confirmation) {
      if (password !== password_confirmation) {
        this.senhaDiferente = true;
        return;
      }

      const customRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%&*?]+){6,12}$/;
      password = customRegex.exec(this.perfilForm.get('password').value);
      password_confirmation = customRegex.exec(this.perfilForm.get('password_confirmation').value);

      if (password === null || password_confirmation === null) {
        this.erroRegex = true;
        return;
      }
    }

    if (this.perfilForm.invalid) {
      return;
    }

    let perfil;
    if (password && password_confirmation) {
      perfil = {
        id: this.perfil.id,
        firstName: this.perfilForm.get('firstName').value,
        lastName: this.perfilForm.get('lastName').value,
        email: this.perfilForm.get('email').value,
        cpf: this.perfilForm.get('cpf').value,
        password: this.perfilForm.get('password').value,
        password_confirmation: this.perfilForm.get('password_confirmation').value
      };
    } else {
      perfil = {
        id: this.perfil.id,
        firstName: this.perfilForm.get('firstName').value,
        lastName: this.perfilForm.get('lastName').value,
        email: this.perfilForm.get('email').value,
        cpf: this.perfilForm.get('cpf').value,
      };
    }

    this.userService.putPerfil(perfil)
      .toPromise()
      .then((result) => {
        this.notifier.notify('success', 'Perfil atualizado com sucesso!');
      }).catch((error) => {
        this.notifier.notify('error', error.error.message);
      });
  }

  limparSenhaError() {
    this.senhaDiferente = false;
    this.erroRegex = false;
  }
}
