import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'app/_services/cliente/cliente.service';
import { AppConstants } from 'app/appconstants';
import { UserService } from 'app/_services/user/user.service';
import { RoleService } from 'app/_services/role/role.service';
import { Cliente } from 'app/_models/cliente/cliente';
import { RegistroService } from 'app/_services/registro/registro.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  clienteForm: FormGroup;
  submitted: boolean;
  id: string;
  clientes: any[] = [];
  registros: any[] = [];
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  busca: string;
  buscaRegistro: string;
  senhaRequired: boolean;
  senhaDiferente: boolean;
  emailJaCadastrado: boolean;
  erroRegex: boolean;
  showList: boolean;
  showAddEdit: boolean;
  showPass: boolean;
  showConfirmPass: boolean;
  title: string;
  btnAction: string;
  notifier: NotifierService;
  selectSettings: any;
  selectedItems: any[] = [];
  lastAction: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private clienteService: ClienteService,
    private userService: UserService,
    private roleService: RoleService,
    private registroService: RegistroService,
    private spinner: NgxSpinnerService
  ) {
    this.notifier = notifierService;
    this.showList = false;
    this.showAddEdit = false;
    this.submitted = false;
    this.senhaRequired = false;
    this.senhaDiferente = false;
    this.emailJaCadastrado = false;
    this.erroRegex = false;
    this.showPass = false;
    this.showConfirmPass = false;

    this.clienteService.getClientes(1, 1)
      .toPromise()
      .then((clientes) => {
        this.itemsPerPage = AppConstants.pagination.itemsPerPage;
        this.currentPage = AppConstants.pagination.currentPage;
        this.totalItems = clientes.total;
      }).catch((error) => {
        if (error.error.message === 'Token has expired') {
          this.userService.tokenExpired();
        }
      });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    this.selectSettings = {
      singleSelection: false,
      text: 'Selecionar Registros',
      selectAllText: 'Selecionar Todos',
      unSelectAllText: 'Remover Todos',
      enableSearchFilter: true,
      classes: 'select-custom'
    };

    this.activatedRoute.data.subscribe(data => {
      switch (data.acao) {
        case 'novo':
          $(document).ready(function() {
            setTimeout(() => {
              $('#email').val('');
              $('#password').val('');
              $('#password_confirmation').val('');
            }, 1500);
          });

          this.clienteFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Novo cliente';
          this.btnAction = 'Salvar';

          this.registroService.getListRegistros()
          .toPromise()
          .then((registros) => {
            registros.data.forEach(registro => {
              let reg = {};
              reg = {
                id: registro.id,
                itemName: registro.nome + ' - ' + registro.processo
              };

              this.registros.push(reg);
            });
          }).catch((error) => {
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }
          });
          break;
        case 'editar':
          this.spinner.show();

          $(document).ready(function() {
            setTimeout(() => {
              $('#password').val('');
              $('#password_confirmation').val('');
            }, 1500);
          });

          this.clienteFormBuilder();
          this.showList = false;
          this.showAddEdit = true;
          this.title = 'Editar cliente';
          this.btnAction = 'Atualizar';

          setTimeout(() => {
            this.registroService.getListRegistros()
            .toPromise()
            .then((registros) => {
              registros.data.forEach(registro => {
                let reg = {};
                reg = {
                  id: registro.id,
                  itemName: registro.nome + ' - ' + registro.processo
                };
                this.registros.push(reg);
              });

              this.clienteService.getCliente(this.id)
              .toPromise()
              .then((cliente) => {
                cliente.password = '';
                cliente.password_confirmation = '';

                cliente.cliente_registros.forEach(registro => {
                  let reg = {};
                  reg = {
                    id: registro.registro_id,
                    itemName: registro.nome
                  };
                  this.selectedItems.push(reg);
                });

                delete cliente.cliente_registros;

                cliente.clienteRegistros = this.selectedItems;
                this.registros.forEach(registro => {
                  cliente.clienteRegistros.forEach(clienteRegistro => {
                    if (registro.id === clienteRegistro.id) {
                      clienteRegistro.itemName = registro.itemName;
                    }
                  });
                });

                cliente.active = parseInt(cliente.active);
                this.clienteForm.setValue(cliente);
                this.spinner.hide();
              }).catch((error) => {
                this.spinner.hide();
                if (error.error.message === 'Token has expired') {
                  this.userService.tokenExpired();
                }
              });

            }).catch((error) => {
              this.spinner.hide();
              if (error.error.message === 'Token has expired') {
                this.userService.tokenExpired();
              }
            });
          }, 100);
          break;
        default:
          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
          break;
      }
    });
  }

  listagem(skip: number, limit: number) {
    this.spinner.show();

    this.clienteFormBuilder();
    this.showList = true;
    this.showAddEdit = false;
    this.clientes = [];
    this.clienteService.getClientes(skip, limit)
      .toPromise()
      .then((clientes) => {
        this.clientes = clientes.data;
        this.spinner.hide();
    }).catch((error) => {
      this.spinner.hide();
      if (error.error.message === 'Token has expired') {
        this.userService.tokenExpired();
      }
    });
  }

  clienteFormBuilder() {
    this.clienteForm = this.formBuilder.group({
      id: ['', []],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      active: ['', Validators.required],
      role: ['', []],
      clienteRegistros: [[], []],
      password: ['', []],
      password_confirmation: ['', []]
    });
  }

  get f() { return this.clienteForm.controls; }

  buscar() {
    this.spinner.show();

    this.clientes = [];
    if (this.busca) {
      this.clienteService.search(this.busca)
        .toPromise()
        .then(resp => {
            this.clientes = resp.data;

            this.itemsPerPage = AppConstants.pagination.itemsPerPage;
            this.currentPage = AppConstants.pagination.currentPage;
            this.totalItems = resp.total;
            this.spinner.hide();
        }).catch(error => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    } else {
      this.clienteService.getClientes(1, 1)
        .toPromise()
        .then((registros) => {
          this.itemsPerPage = AppConstants.pagination.itemsPerPage;
          this.currentPage = AppConstants.pagination.currentPage;
          this.totalItems = registros.total;

          this.listagem(AppConstants.pagination.initSkip, AppConstants.pagination.itemsPerPage);
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    }
  }

  salvarCliente() {
    this.submitted = true;
    this.spinner.show();

    const active = (this.clienteForm.get('active').value === true || this.clienteForm.get('active').value === 1) ? 1 : 0;

    this.clienteForm.controls.active.setValue(active);

    if (this.clienteForm.invalid) {
      this.spinner.hide();
      setTimeout(() => {
        this.notifier.notify('error', 'Os campos obrigatórios precisam ser preenchidos');
      }, 500);
      return;
    }

    let password = this.clienteForm.get('password').value;
    let password_confirmation = this.clienteForm.get('password_confirmation').value;

    if (password && password_confirmation) {
      if (password !== password_confirmation) {
        this.spinner.hide();
        this.senhaDiferente = true;
        return;
      }

      const customRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%&*?]+){6,12}$/;
      password = customRegex.exec(this.clienteForm.get('password').value);
      password_confirmation = customRegex.exec(this.clienteForm.get('password_confirmation').value);

      if (password === null || password_confirmation === null) {
        this.spinner.hide();
        this.erroRegex = true;
        return;
      }
    }

    if (this.clienteForm.get('id').value) {
      if (this.isEmpty(this.clienteForm.get('password').value) || this.isEmpty(this.clienteForm.get('password_confirmation').value)) {

        setTimeout(() => {
          const cliente: Cliente = {
            id: this.clienteForm.get('id').value,
            firstName: this.clienteForm.get('firstName').value,
            lastName: this.clienteForm.get('lastName').value,
            email: this.clienteForm.get('email').value,
            role: this.clienteForm.get('role').value,
            clienteRegistros: this.selectedItems,
            active: this.clienteForm.get('active').value
          };

          this.clienteService.patchCliente(cliente)
          .toPromise()
          .then(() => {
            this.spinner.hide();
            setTimeout(() => {
              this.router.navigateByUrl('/dashboard/clientes');
              this.notifier.notify('success', 'cliente atualizado com sucesso!');
            }, 500);
          }).catch((error) => {
            this.spinner.hide();
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }

            if (error.error[2] && error.error[2].includes('clientes_email_unique')) {
              this.emailJaCadastrado = true;
            }
          });
        }, 100);
      } else {
        setTimeout(() => {
          this.clienteService.patchCliente(this.clienteForm.value)
          .toPromise()
          .then(() => {
            this.spinner.hide();
            setTimeout(() => {
              this.router.navigateByUrl('/dashboard/clientes');
              this.notifier.notify('success', 'Cliente atualizado com sucesso!');
            }, 500);
          }).catch((error) => {
            this.spinner.hide();
            if (error.error.message === 'Token has expired') {
              this.userService.tokenExpired();
            }

            if (error.error[2] && error.error[2].includes('clientes_email_unique')) {
              this.emailJaCadastrado = true;
            }
          });
        }, 100);
      }
    } else {
      this.roleService.getRoles()
        .toPromise()
        .then((roles) => {
          roles.forEach(role => {
            if (role.nome === 'cliente') {
              this.clienteForm.controls.role.setValue(role.id);

              this.clienteService.postCliente(this.clienteForm.value)
                .toPromise()
                .then(() => {
                  this.spinner.hide();
                  setTimeout(() => {
                    this.router.navigateByUrl('/dashboard/clientes');
                    this.notifier.notify('success', 'Cliente salvo com sucesso!');
                  }, 500);
                }).catch((error) => {
                  this.spinner.hide();
                  if (error.error.message === 'Token has expired') {
                    this.userService.tokenExpired();
                  }

                  if (error.error.password[0] === 'The password field is required.') {
                    this.senhaRequired = true;
                  }

                  if (error.error.password[0] === 'The password confirmation does not match.') {
                    this.senhaDiferente = true;
                  }

                  if (error.error[2] && error.error[2].includes('clientes_email_unique')) {
                    this.emailJaCadastrado = true;
                  }
                });
            }
          });
        }).catch((error) => {
          this.spinner.hide();
          if (error.error.message === 'Token has expired') {
            this.userService.tokenExpired();
          }
        });
    }
  }

  pageChanged(event) {
    this.currentPage = event;

    const itemsPerPage = AppConstants.pagination.itemsPerPage;
    const skip = (this.currentPage === 1) ? (this.currentPage - 1) * itemsPerPage : (this.currentPage - 1) * itemsPerPage - 1;

    this.listagem(skip, this.currentPage * itemsPerPage - 1);
  }

  showPassowrd() {
    this.showPass = !this.showPass;
  }

  showConfirmPassowrd() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  limparSenhaError() {
    this.senhaRequired = false;
    this.senhaDiferente = false;
    this.emailJaCadastrado = false;
    this.erroRegex = false;
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  onItemSelect(item: any) {}

  onItemDeSelect(item: any) {}

  onSelectAll(items: any) {}

  onDeSelectAll(items: any) {}
}
