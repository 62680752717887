export abstract class AuthInterface {
  constructor() { }

  abstract getToken();

  abstract isLoggedIn();

  abstract getCurrentUsuario();

  abstract getCurrentPerfil();
}
