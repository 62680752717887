import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment } from '@angular/router';

export abstract class AuthClienteGuardInterface {
  constructor() { }

  abstract canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot);

  abstract canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot);

  abstract canLoad(route: Route, segments: UrlSegment[]);
}
