export abstract class AuthClienteInterface {
    constructor() { }

    abstract getToken();

    abstract isClientLoggedIn();

    abstract getCurrentCliente();

    abstract getCurrentClientePerfil();
}
