import { Injectable } from '@angular/core';
import { AuthClienteInterface } from './auth-cliente-interface';

@Injectable({
  providedIn: 'root'
})
export class AuthClienteService extends AuthClienteInterface {

  constructor() {
    super();
  }

  public getToken() {
    const current = this.getCurrentCliente();
    return current ? current.token : '';
  }

  public isClientLoggedIn() {
    return this.getToken();
  }

  public getCurrentCliente() {
    return JSON.parse(localStorage.getItem('_pb.client'));
  }

  public getCurrentClientePerfil() {
    const user = JSON.parse(localStorage.getItem('_pb.client'));
    return user ? user.user : '';
  }
}
