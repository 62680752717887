import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
// import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_services/guard/auth.guard';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ClienteLayoutComponent } from './layouts/cliente-layout/cliente-layout.component';
import { LoginComponent } from './login/login.component';
import { SistemaComponent } from './sistema/sistema.component';
import { AuthClienteGuard } from './_services/guard-cliente/auth.cliente.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'area-cliente',
    component: ClienteLayoutComponent,
    canActivate: [AuthClienteGuard],
    canActivateChild: [AuthClienteGuard],
    children: [{
      path: '',
      loadChildren: './layouts/cliente-layout/cliente-layout.module#ClienteLayoutModule'
    }]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'sistema',
    component: SistemaComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    // BrowserModule,
    RouterModule.forRoot(routes, {
      //  useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
